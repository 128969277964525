/* Header
=======================*/

.header {
    width: 100%;
}

/* .header__logo img {
    width: 210px;
} */

.header_logo_img {
  width: 255px;
  height: 80px;
}

.header__logo {
  display: flex;
  align-items: center;
}

.header__inner {
    padding-top: 4px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
}

.nav {
    display: flex;
    margin-left: 35px;
}

.nav__link {
    margin-left: 24px;
    font-size: 16px;
    font-weight: 700;
    color: #003399;
    text-transform: uppercase;
    text-decoration: none;
}

.nav__link:first-child {
    margin-left: 0
}

.nav__link:hover {
    color: #FF0000;
}

.nav__link:active {
    color: #FF0000;
    opacity: 0.6;
}

.header__middle {
    display: flex;
}

.header__location {
    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    text-decoration: underline;
    color: #00BFFF;
    transition: all 0.2s;
}

.header__location:after {
    content: "";
    background: url('../../../images/down-arrow.svg') center no-repeat /cover;
    width: 6px;
    height: 7px;
    float: right;
    margin: 0 0 0 4px;

}

.phone {
    margin: 0 32px;
    display: flex;
    align-items: center;
}

.phone:before {
    content: "";
    background-image: url('../../../images/phone-ico.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 25px;
    height: 24px;
    float: left;
    margin: 0 8px 0 0;

}

.phone__number {
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    color: #000000;
}

.phone__text {
    font-weight: 300;
    font-size: 10px;
    line-height: 12px;
    color: #000000;
}

.login__btn {

    display: flex;
    align-items: center;
    justify-content: center;

    padding: 7px 16px;
    border: 1.5px solid #00BFFF;
    border-radius: 2px;
    cursor: pointer;

    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    font-size: 14px;
    color: #00BFFF;
    text-decoration: none;

    transition: background .1s linear;
}

.login__btn:before {
    content: "";
    background-image: url('../../../images/login-ico.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 24px;
    height: 24px;
    float: left;
    margin: 0 4px 0 0;
}

.no_border {
  border: unset;
}

.burger{
  display: none;
  margin-left: 20px;
  padding: 0;
  background: none;
  cursor: pointer;
  border: 0;
  outline: none;

}

.burger__item {
  display: block;
  width: 32px;
  height: 3px;
  font-size: 0px;
  color: transparent;
  background: #00bfff;
  position: relative;
}

.burger__item:before,
.burger__item:after {
  content: "";
  height: 100%;
  background: #00bfff;
  position: absolute;
  right: 0;
  z-index: 1;
}

.burger__item:before {
  width: 32px;
  top: -8px;
}

.burger__item:after {
  width: 32px;
  bottom: -8px;
}

.loginWrapper {
  position: relative;
}

.userMenu {
  font-size: 14px;
  position: absolute;
  bottom: -56px;
  left: 16px;
  z-index: 100;
  background-color: #ffffff;
  width: 130px;
  border-radius: 6px;
  box-shadow: 2px 1px 4px 2px rgba(0, 0, 0, .2);
  padding: 10px 10px 0 10px;
}

.h-user-name {
  /* width: 50px; */
    width: 100px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

/* Media
======================*/
@media (max-width: 925px) {
  .h-user-name {
    display: none;
  }

  .userMenu {
    bottom: -61px;
    left: -66px;
  }
}

@media (max-width: 823px) {
  .phone__text {
    display: none;
  }

  .phone__number {
    /* font-size: 12px; */
  }
}

@media (max-width: 785px) {
  .nav__link {
    margin-left: 9px;
  }

  .nav {  
    margin-left: 15px;
  }
}
