.tr_intro {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 235px;
    background: url("../../../images/tr-search-back.jpg") no-repeat center;
    background-size: cover;
    min-width: 740px;
    margin-bottom: 35px;
}

.tr_intro__wave {
    position: absolute;
    width: 100%;
    height: 25px;
    bottom: -1px;
    left: 0;
    z-index: 100px;
    width: 100%;
    background-image: url('../../../images/white-wave.svg');
    background-position: center;
    background-repeat: repeat-x;
}
/* top lene ---*/
.tr_topline_wrapper {
    display: flex;
    margin-bottom: 25px;
}

.tr_topline_left {
    flex: 0 0 280px;
}

.tr_topline_right {
    flex: 1;
    padding-left: 32px;
    height: 40px;
}


/* ------------- */
.tr_wrapper {
    display: flex;
    height: 100%;
}

.tr_left_bar_wrapper {
    flex: 0 0 280px;
}

.tr_left_bar {
    background-color: #E7F7FF;
    padding: 0 16px;
}

/* ----- */
.tr_right_bar {
    position: relative;
    flex: 1;
    padding-left: 32px;
}

.tour_item {
    display: flex;
    height: 246px;
    margin-bottom: 35px;
    box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.1), 0px 1px 1px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
}

.tour_item:first-child {
    margin-top: 0;
}

.tr_hotel_image {
    position: relative;
    height: 246px;
    flex: 0 0 280px;
    border-radius: 6px 0 0 6px;
    -webkit-border-radius: 6px 0 0 6px;
    -moz-border-radius: 6px 0 0 6px;
}

.tr_hotel_image_link {
    width: 100%;
    height: 100%;
    display: block;
}

.tr_distance {
    position: absolute;
    bottom: 19px;
    left: 25px;
}

.tr_airport_distance_w {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    font-family: 'Roboto';
    color: rgba(255, 255, 255, 0.9);
}

.tr_airport_distance_km {
    padding: 2px 8px;
    margin-right: 9px;
    border: 1px solid rgba(255, 255, 255, 0.9);
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
}

/* ------- */
.tr_tour_info {
    padding: 24px;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

/* .tr_hotel_info_w1 {
    border-bottom: 1px solid rgba(0, 0, 0, .1);
} */

.tr_hotel_info_w2 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 7px;
}

.tr_hotel_name {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    color: #333333;
    text-decoration: none;
}

.tr_category_star {
    margin-right: 3px;
}

.tr_location {
    font-family: 'Roboto';
    font-size: 14px;
    font-weight: 600;
    color: #00BFFF;
    margin-top: 8px;
}

.tr_location:before {
    content: "";
    background-image: url('../../../images/blue-pin.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 10px;
    height: 10px;
    float: left;
    margin: 3px 3px 0 0;
}

.tr_hotel_raiting_w1 {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.tr_hotel_raiting_w2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 67px;
    height: 32px;
    background-color: #8FCC14;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    padding: 6px 8px;
    margin-bottom: 6px;
}

.tr_hotel_ta_raiting {
    width: 26px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    color: #FFFFFF;
    text-align: center;
}

.tr_hotel_ta_reviews_count {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    color: #333333;
    /* text-decoration-line: underline; */
}

.tr_hotel_description {
    /* height: 57px; */
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #333333;
    margin-bottom: 5px;
}

.tr_hotel_facilities_w1 {
    align-items: center;
    border-top: 1px solid rgba(0, 0, 0, .1);
}

.tr_hotel_facilities_first_line {
    display: flex;
    margin-top: 10px;
}

.tr_hotel_facilities_second_line {
    display: flex;
    margin-top: 10px;
}

.tr_hotel_facility {
    display: flex;
    align-items: center;
    color: #333;
    font-size: 14px;
    font-family: 'Roboto';
    flex: 1 1 50px;
}

.facility_ac:before {
    content: "";
    background-image: url('../../../images/ac-blue-icon.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 21px;
    height: 24px;
    float: left;
    margin: 0 8px 0px 0;
}

.facility_wifi:before {
    content: "";
    background-image: url('../../../images/wifi-blue-icon.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 21px;
    height: 24px;
    float: left;
    margin: 0 8px 0 0;
}

.facility_beachline:before {
    content: "";
    background-image: url('../../../images/beach-line-blue-icon.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 21px;
    height: 24px;
    float: left;
    margin: 0 8px 0 0;
}

.facility_beach_type:before {
    content: "";
    background-image: url('../../../images/beach-type-blue-icon.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 21px;
    height: 24px;
    float: left;
    margin: 0 8px 0 0;
}

/* ------- */
.tr_tour_price_info {
    flex: 0 0 208px;
}

.tr_price_details_w {
    height: 174px;
    width: 100%;
    border-left: 1px solid rgba(0, 0, 0, .1);
    padding: 16px;
}

.tr_price_details {
    font-family: 'Roboto';
    font-size: 12px;
    color: #999999;
}

.tr_price_w {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 72px;
    width: 100%;
    background-color: #FFDB4D;
    text-decoration: none;
    border-radius: 0 0 6px 0;
    -webkit-border-radius: 0 0 6px 0;
    -moz-border-radius: 0 0 6px 0;
}

.tr_price {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    color: #333333;
}

.tr_oil_taxes {
    font-size: 12px;
    font-family: 'Roboto';
    font-weight: 300;
    color: #333333;
    text-align: center;
}

.tr_price_currencies_w {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.tr_byn_price {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: rgb(69, 69, 69);
}

/* filters */
.filter_item {
    padding: 16px 0;
    border-top: 1px solid rgba(0, 0, 0, .1);
}

.filter_item:first-child {
    border-top: 0;
}

.hotel_class_title {
    font-size: 16px;
    font-weight: 500;
    font-family: 'Roboto';
    color: #333333;
}

.filters_container {
    padding-left: 27px;
}

.filter_description {
    margin-left: 5px;
}

.hotel_sort {
    font-size: 16px;
    font-family: 'Roboto';
    font-weight: 400;
    color: #333333;
}

.sort_option {
    /* margin-left: 19px; */
    padding: 10px 25px;
    font-size: 16px;
    font-family: 'Roboto';
    font-weight: 400;
    color: #00BFFF;
}

.sort_option_selected {
    color: #333333;
    /* padding: 10px 25px; */
    border: 1px solid rgba(0, 0, 0, .1);
    border-radius: 16px;
}

.hotel_sort_title {
    margin-right: 19px;
}

.tr_meals_code {
    display: inline-block;
    width: 39px;
}

/* spinner */
.spinner_wrapper {
    padding-top: 150px;
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.6);
    position: absolute;
    z-index: 1;
}

.tr_invisible {
    display: none;
}

.tr_filter_button {
    padding: 8px 20px;
    color: #FFF;
    font-family: Roboto;
    font-size: 13px;
    font-weight: 400;
}

.tr_tablet_filter_buttom {
    display: none;
    margin-bottom: 34px;
    width: 100%;
    padding: 14px;
    border-radius: 0px 0px 4px 4px;
    background: rgba(220, 244, 255, 0.40);
}
  
.tr_tablet_filter_buttom:active {
    background: rgba(201, 226, 237, 0.40);
}

.tr_tablet_filter_buttom_text_w {
    margin: 0 auto;
    width: 110px;
    display: flex;
    align-items: center;
}

.tr_tablet_filter_buttom_text {
    margin-right: 8px;
    color: #333;
    font-family: Roboto;
    font-size: 16px;
    font-weight: 500;
}

.tr_not_found {
    margin-top: 15px;
    font-size: 18px;
    color: #333333;
}

.search_progress_bar {
    display: flex;
    align-items: center;
    margin-top: 3px;
}

.hd_look_for_prices {
    margin-right: 10px;
    color: rgb(153, 153, 153);
    font-family: Roboto;
    font-size: 14px;
    font-weight: 500;
}

/* Media
======================= */

@media (max-width: 1230px) {
    .tr_tablet_filter_buttom {
        display: block;
    }

    .tr_wrapper {
        position: relative;
        min-height: 1200px;
    }

    .tr_left_bar_wrapper {
        position: absolute;
        z-index: 5;
        top: 2px;
        left: 33px;
    }

    .tr_left_bar {
        border-radius: 6px;
    }

    .tr_topline_left {
        display: none;
    }

    .tablet_menu_invisible {
        display: none;
    }
}

@media (max-width: 1000px) {
    .tr_hotel_info_w2 {
        display: flex;
        flex-direction: column-reverse;
        align-items: start;
        /* margin-bottom: 7px; */
    }

    .tr_hotel_facility {
        font-size: 10px;
    }

    .tr_tour_info {
        padding: 16px;
    }

    .tr_hotel_name {
        font-size: 18px;
        margin-top: 2px;
    }

    .tr_hotel_description {
        display: none;
    }

    .tr_hotel_image {
        flex: 0 0 224px;
    }

    .tr_hotels_stars_w1 {
        margin-top: 10px;
    }

    .tr_location {
        font-size: 12px;
    }
}

/* 740-1000 */
@media (max-width: 840px) {
    
}