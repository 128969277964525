.modal {
  width: 340px;
  height: 400px;
  background-color: #ffffff;
  border-radius: 4px;
  padding: 15px;
}

.modal-back {
  padding-top: 100px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.8);
  position: absolute;
  z-index: 100;
  top: 0;
  left: 0;
}

.closeWrapper {
  display: flex;
  justify-content: flex-end;
}

.closeButton {
  color: #b5b8ba;
  font-size: 16px;
}

.loginModalTitle {
  margin-top: 10px;
  font-family: 'Roboto', sans-serif;
  font-size: 18px;
  weight: 400;
}

.form_input {
  margin-top: 4px;
  width: 252px;
  padding: 8px;
  border: 1px solid #cdcfd1;
  border-radius: 3px;
  color: #454545;
}

input.form_input:focus {
  outline-width: 0px;
  outline-style: solid;
  outline-color: rgb(0, 51, 153);
  box-shadow: 0px 0px 2px 1px rgba(0, 51, 153, 0.9);
}

.modalInputWrapper {
  margin-top: 20px;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 400;
  color: #b5b8ba;
}

.login_btn {
  margin-top: 25px;
  width: 252px;
}

.login_form {
  margin-left: 27px;
  margin-top:28px;
}

.btn_dis {
  background-color: rgb(210, 210, 212) !important;
  color: rgb(255, 255, 255);
}

.invalidInput {
  border-color: rgb(199, 20, 23);
}

input.form_input.invalidInput:focus {
  outline-width: 0px;
  outline-style: solid;
  outline-color: rgb(199, 20, 23);
  box-shadow: 0px 0px 2px 1px rgba(199, 20, 23, 0.9);
}

.showReg_wparrer {
  text-align: center;
  width: 252px;
  margin-top: 10px;
  font-size: 12px;
}

.showReg {
  font-size: 12px;
  font-weight: 500;
  color: #003399;;
}

.loginFormError {
  color:rgb(199, 20, 23);
}

.login_form_inp {
  width: 252px !important;
}

.reg_pass_hint {
  margin-top: 10px;
  font-size: 12px;
  color: #333333;
}
