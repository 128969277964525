/* Search Form */

.search__form {
  display: flex;
  flex-direction: column;
}

.sf__input__wrapper {
  height: 62px;
  width: 100%;
  display: flex;
}

.sf_submit {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #FFDB4D;
  /* width: 18%; */
  flex: 1 2 198px;
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  font-size: 18px;
  color: #003399;
  text-decoration: none;
  border: 0;
  border-radius: 0 4px 4px 0;
}
