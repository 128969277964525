.cross__ico {
  position: absolute;
  top: 21px;
  right: 0;
  z-index: 1000;
}

.input_search_destination {
  width: 100%;
  padding: 0;
  border: 0;
  /* height: 22px; */
  font-family: 'Roboto', sans-serif;
  font-size: 17px;
  outline: none;
  color: #454545;
  background-color: #eaf8ff;
}

@media (max-width: 1073px) {
  .input_search_destination {
    font-size: 14px;
  }
}
