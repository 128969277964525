/* Footer
========================*/
.footer {
    padding: 40px;
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    background-color: #272727;
    min-width: 740px;
}

.footer__inner {
    display: flex;
    flex-direction: column;
    width: 560px;
    margin: 0 auto;
    padding: 30px 0;
    text-align: center;
    align-items: center;
}

.footer_logo {
    margin-bottom: 15px;
}

.footer__text {
    margin-bottom: 20px;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    color: #FFFFFF;
    opacity: .65;
}

.footer__nav {
    width: 460px;
    margin-bottom: 24px;
    padding-bottom: 16px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #00BFFF;
}

.footer__nav__item {
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    color: rgb(202, 202, 202);
    text-decoration: none;
}

.footer__phone {
    display: flex;
    align-items: center;
    justify-content: center;

    margin-bottom: 7px;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #ffffff;
}

.footer__phone__img {
  width: 17px;
  height: 17px;
  margin: 0 11px 0 0;
}

.footer__email {
    display: flex;
    align-items: center;
    justify-content: center;

    margin-bottom: 26px;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #ffffff;
}

.social {
    display: flex;
}

.social__item {
    margin-left: 12px;
}

.social__item:first-child {
    margin-left: 0;
}

.footer__info {
    margin-bottom: 20px;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-size: 11px;
    line-height: 12px;
    color: #FFFFFF;
    opacity: .65;
}

.conf__policy {
    margin-bottom: 20px;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-size: 11px;
    line-height: 15px;
    color: #FFFFFF;
    opacity: 0.65;
}

.copyright {
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    font-size: 14px;
    line-height: 15px;
    color: #ffffff;
    margin-right: 20px;
}

.foot_payment_methods {
    margin-bottom: 20px;
    display: flex;
    justify-content: start;
    flex-wrap: wrap;
}

.foot_payment_item {
    margin-right: 20px;
    margin-bottom: 10px;
}

.foot_copyright_w {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: start;
}

.pay-alfa {
    width: 171px;
    height: 35px;
}

.pay-apple {
    width: 55px;
    height: 35px;
}

.pay-bel {
    width: 31px;
    height: 35px;
}

.pay-belcard {
    width: 153px;
    height: 35px;
}

.pay-id-check {
    width: 167px;
    height: 35px;
}

.pay-mastercard {
    width: 45px;
    height: 35px;
}

.pay-samsung {
    width: 78px;
    height: 35px;
}

.pay-visa-secure {
    width: 35px;
    height: 35px;
}

.pay-visa {
    width: 108px;
    height: 35px;
}

.pay-ya {
    width: 82px;
    height: 35px;
}

.foot_info_block_w {
    display: flex;
    justify-content: center;
    margin-bottom: 40px;
}

.foot_info_block {
    margin-right: 40px;
    width: 300px;
}

.foot_block_title {
    margin-bottom: 15px;
    font-family: 'Roboto';
    font-weight: 600;
    font-size: 17px;
    color: #ffffff;
}

.foot_info_text {
    font-family: 'Roboto';
    font-size: 12px;
    color: rgb(202, 202, 202);
}