.pr_user_name {
    font-family: 'Roboto';
    font-size: 22px;
    color: #333333;
}

.pr_orders_not_found {
    font-family: 'Roboto';
    font-size: 18px;
    color: #333333;
}

.pr_section_title {
    font-family: 'Roboto';
    font-size: 22px;
    color: #333333;
    margin-bottom: 20px;
}

.pr_section_w {
    margin-bottom: 30px;
}