.hotelsFilter {
  margin-top: 12px;
}
  
.hotelsFilter .react-autosuggest__input {
  width: 246px;
  height: 32px;
  padding: 7px 13px;
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  font-size: 14px;
  border: 0;
  border-radius: 2px 2px 0 0;
}
  
.hotelsFilter .react-autosuggest__input--focused {
  outline: none;
}

.hotelsFilter .react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.hotelsFilter .react-autosuggest__suggestions-container {
  display: none;
}

.hotelsFilter .react-autosuggest__suggestions-container--open {
  margin-top: 12px;
  display: block;
  width: 246px;
  height: 150px;
  overflow: auto;
  background-color: #ffffff;
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  font-size: 14px;
  border-radius: 4px;
}

.hotelsFilter .react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.hotelsFilter .react-autosuggest__suggestion {
  cursor: pointer;
  padding: 7px 13px;
}

.hotelsFilter .react-autosuggest__suggestion--highlighted {
  background-color: rgba(0,191,255, .9);
  color: #ffffff;
}

.tr_topline_right {
  display: flex;
  justify-content: space-between;
}

.selectedHotel {
  margin-top: 12px;
  display: flex;
  width: 244px;
}

.remove_hotel_filter {
  margin-left: 10px;
}

.hotel_filter_spinner {
  margin-left: 5px;
}

.react-autosuggest__input:disabled {
  background-color: #E7F7FF;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 2px 2px 0 0;
}

.hotels_error {
  font-family: 'Roboto';
  color: #333333;
  font-weight: 400;
  font-size: 12px;
  margin-top: 8px;
}