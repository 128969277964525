.hotel_intro {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 235px;
    background: url("../../../images/hd-intro-back.jpg") no-repeat center;
    background-size: cover;
    min-width: 740px;
    margin-bottom: 35px;
}

.hotel_intro__wave {
    position: absolute;
    width: 100%;
    height: 25px;
    bottom: -1px;
    left: 0;
    z-index: 100px;
    width: 100%;
    background-image: url('../../../images/white-wave.svg');
    background-position: center;
    background-repeat: repeat-x;
}

/* tours ****************/
.hd_header_wrapper {
    margin: 40px 0 20px 0;
    border-radius: 6px 6px 0 0;
    box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.10), 0px 1px 1px 0px rgba(0, 0, 0, 0.10);
    width: 100%;
}

.hd_tours_hotel_name {
    padding: 32px;
    color: #383838;
    font-family: Roboto;
    font-size: 24px;
    font-weight: 700;
}

.hd_dates_carousel_wrapper {
    display: flex;
    position: relative;
}

.hd_scroll_arrow {
    display: flex;
    align-items: center;
    height: 88px;
    width: 40px;
    z-index: 1;
}

.hd_scroll_arrow_left {
    position: absolute;
    top: 4px;
    left: 0;
    justify-content: left;
    background: linear-gradient(90deg, rgba(2,0,36,0.1503195028011205) 0%, rgba(255,255,255,0) 100%);
}

.hd_scroll_arrow_right {
    position: absolute;
    top: 4px;
    right: 0;
    justify-content: right;
    background: linear-gradient(270deg, rgba(2,0,36,0.1503195028011205) 0%, rgba(255,255,255,0) 100%);
}

.hd_tours_carousel_scroll_w {
    display: flex;
    overflow: auto;
    white-space: nowrap;
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
}

.hd_tours_carousel_scroll_w::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
}

.hd-tour-date-w {
    position: relative;
    display: flex;
    align-items: center;
    background-color: #00BFFF;
    width: 158px;
    height: 88px;
    border-right: 1px solid #ffffff;
}

.hd-tour-date-w-selected {
    background-color: #FFFFFF;
    border-right: 1px solid rgba(0, 0, 0, 0.05);
}

.hd-tour-date-yellow-border {
    width: 100%;
    height: 4px;
}

.hd-tour-date-yellow-border-selected {
    width: 100%;
    height: 4px;
    background-color: #FFDB4D;
}

.hd-tour-date-button {
    border-right: 1px solid #333333;
}

.hd-tour-date-border {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 36px;
}

.hd-tour-date {
    margin-bottom: 4px;
    color: #FFF;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
}

.hd-tour-date-selected {
    color: #00BFFF;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 500;
}

.hd-tour-date-price {
    color: #FFF;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 500;
    display: flex;
    align-items: center;
}

.hd-tour-date-price-selected {
    color: #00BFFF;
    font-family: Roboto;
    font-size: 13px;
    font-weight: 700;
}

.hd-tour-date-price-curr {
    color: #FFF;
    font-family: Roboto;
    font-size: 16px;
    font-weight: 700;
}

.hd-tour-date-price-curr-selected {
    color: #00BFFF;
    font-family: Roboto;
    font-size: 18px;
    font-weight: 700;
}

.hd-tour-date-price-byn-selected {
    color: #00BFFF;
    font-family: Roboto;
    font-size: 10px;
    font-weight: 500;
}

.hd-tour-price-offer {
    color: #333333;
    font-family: Roboto;
    font-size: 20px;
    font-weight: 700;
}

.hd-tour-operator {
    color: #939CB0;
    font-family: Roboto;
    font-size: 12px;
    font-weight: 300;
    margin: 3px 0 2px 0;
    padding-right: 6px;
}

.hd-tour-button {
    margin-bottom: 8px;
    border-radius: 4px;
    border-radius: 6px;
    box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.10), 0px 1px 1px 0px rgba(0, 0, 0, 0.10);
}

.hd-tour-button:hover {
    background-color: #FFDB4D;
}

.hd-tour-link {
    text-decoration: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 128px;
    height: 53px;
}

.hd-other-tour-link {
    text-decoration: none;
}

.hd-offers-w {
    width: 100%;
    display: none;
}

.offer_details_selected {
    display: block;
}

.hd-room-type {
    width: 250px;
    color: #00BFFF;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 700;
    text-decoration-line: underline;
    border-right: 1px dashed rgba(145, 148, 153, .4);
    flex: 0 0 261px;
}

.disabledContent {
    display: none;
}

.hd_wrapper {
    min-height: 100%;
}

.hd_spinner_wrapper {
    padding-top: 150px;
    display: flex;
    justify-content: center;
    width: 100%;
    height: 500px;
    background-color: rgba(255, 255, 255, 0.6);
    /* position: absolute;
    z-index: 1; */
}

.hd_invisible {
    display: none;
}

.hd_arrow_invisible {
    display: none;
}

/* grouped prices */
.hd_day_room_w {
    display: flex;
    margin: 20px 0;
    padding: 20px 0 20px 20px;
    border-radius: 6px;
    box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.10), 0px 1px 1px 0px rgba(0, 0, 0, 0.10);
    width: 100%;
}

.hd_nights_wrapper {
    display: flex;
    overflow: auto;
    width: 960px;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.hd_nights_wrapper::-webkit-scrollbar { 
    display: none;
}

.hd_night_collumn {
    width: 160px;
    display: flex;
    flex: 0 0 160px;
    flex-direction: column;
    align-items: center;
    border-right: 1px dashed rgba(145, 148, 153, .4);
}

.hd_meals_w {
    height: 169px;
    margin-bottom: 20px;
    width: 100%;
}

.hd_meals_wr {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.hd_meal_top_line {
    margin-bottom: 20px;
    width: 80px;
    border-bottom: 1px dashed rgba(145, 148, 153, .6);
}

.hd_meal_name {
    width: 120px;
    margin-bottom: 8px;
    color: #333333;
    font-family: Roboto;
    font-size: 13px;
    font-weight: 600;
}

.hd_nights_count_w {
    display: flex;
    align-items: center;
    margin-bottom: 4px;
}

.hd_nights_count {
    margin-right: 5px;
    color: rgb(145, 148, 153);
    font-family: Roboto;
    font-size: 18px;
    font-weight: 400;
}

.hd_gray_moon {
    width: 19px;
}

.hd_date_to {
    margin-bottom: 20px;
    color: rgb(145, 148, 153);
    font-family: Roboto;
    font-size: 13px;
}

.hd_byn_price {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: rgb(69, 69, 69);
}

.hd-tour-price-per-night-w {
    display: flex;
    justify-content: center;
    margin-bottom: 15px;
}

.hd-tour-price-per-night {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #333333;
}

.hd-tour-price-per-night-mon {
    width: 11px;
}

.hd_other_offer_wrapper {
    width: 100%;
    /* position: relative; */
}

.hd_ather_offer_item_operator {
    margin-right: 10px;
    font-family: 'Roboto';
    font-weight: 500;
    font-size: 14px;
    color: #333333;
}

.hd-tour-price-other-offer {
    font-family: 'Roboto';
    font-weight: 500;
    font-size: 14px;
    color: #333333;
}

.hd_byn_price_other {
    font-family: 'Roboto';
    font-weight: 400;
    font-size: 12px;
    color: #939CB0;
}

.hd_other_offers_w {
    margin-top: 7px;
    min-width: 160px;
    position: absolute;
    z-index: 3;
    background-color: #FFFFFF;
    border-radius: 6px 6px 0 0;
    box-shadow: rgba(191, 191, 172, 0.5) 0px 2px 20px 0px;
}

.hd_other_offers {
    font-size: 12px;
    font-weight: 500;
    color: rgba(0, 53,  153, .8);
}

.hd_other_offers_arrow {
    width: 13px;
    transform: rotate(180deg);
}

.hd_nights_line_button {
    position: absolute;
    z-index: 1;
    width: 10px;
}

.hd_nights_button_left {
    top: 0;
    left: 3px;
}

.hd_nights_button_right {
    top: 0;
    right: 3px;
}

.hd_nights_line_w {
    position: relative;
    flex: 0 0 960px;
}

.hd_other_offer_button {
    width: 100%;
}

.hd_other_offer_link {
    text-decoration: none;
}

.hd_ather_offer_item {
    padding: 10px;
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid rgba(145, 148, 153, .2);
}

.hd_ather_offer_item:hover {
    background-color: rgba(0,191,255, .05);
    color: #ffffff;
}

.hd_nights_collumn_0 {
    left: 0;
}

.hd_nights_collumn_1 {
    left: 0;
}

.hd_nights_collumn_2 {
    left: 160px;
}

.hd_nights_collumn_3 {
    left: 320px;
}

.hd_nights_collumn_4 {
    left: 480px;
}

.hd_nights_collumn_5 {
    left: 640px;
}

.hd_nights_collumn_6 {
    left: 800px;
}

/* filters */
.hd_tablet_filter_buttom {
    width: 150px;
    padding: 14px;
    border-radius: 0px 0px 4px 4px;
    background: rgba(220, 244, 255, 0.40);
}
  
.hd_tablet_filter_buttom:active {
    background: rgba(201, 226, 237, 0.40);
}

.hd_tablet_filter_buttom_text_w {
    margin: 0 auto;
    width: 110px;
    display: flex;
    align-items: center;
}

.hd_tablet_filter_buttom_text {
    margin-right: 8px;
    color: #333;
    font-family: Roboto;
    font-size: 16px;
    font-weight: 500;
}

.hd_offers_wrapper {
    min-height: 500px;
    position: relative;
}

/* filters bar */
.hd_left_bar_wrapper {
    width: 280px;
    position: absolute;
    z-index: 5;
    top: 0px;
    left: 0px;
}

.hd_left_bar {
    min-height: 415px;
    background-color: #E7F7FF;
    padding: 0 16px;
    border-radius: 6px;
}

.hd_filter_item {
    padding: 16px 0;
    border-top: 1px solid rgba(0, 0, 0, .1);
}

.hd_filter_item:first-child {
    border-top: 0;
}

/* media *******/
@media (max-width: 1000px) {
    .hd-room-type-dates-w {
        flex-direction: column;
    }

    .hd-room-dates-w {
        justify-content: start;
    }

    .hd-room-nights {
        text-align: left;
    }

    .hd-room-flight-details {
        margin-top: 13px;
        align-items: start;
    }
}

/* 5 collumns */
@media (max-width: 1310px) {
    .hd-room-type {
        flex: 0 0 201px;
    }
}

/* 5 collumns */
@media (max-width: 1234px) {
    .hd-room-type {
        flex: 0 0 201px;
    }

    .hd_nights_wrapper {
        width: 800px;
    }

    .hd_nights_line_w {
        flex: 0 0 800px;
    }
}

/* 4 collumns */
@media (max-width: 1075px) {
    .hd-room-type {
        flex: 0 0 201px;
    }

    .hd_nights_wrapper {
        width: 640px;
    }

    .hd_nights_line_w {
        flex: 0 0 640px;
    }
}

/* 3 collunms */
@media (max-width: 915px) {
    .hd-room-type {
        flex: 0 0 201px;
    }

    .hd_nights_wrapper {
        width: 480px;
    }

    .hd_nights_line_w {
        flex: 0 0 480px;
    }
}