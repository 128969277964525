/* City from */
.sf__city__from {
  position: relative;
  width: 212px;
  padding: 11px 16px;
  display: flex;
  background: #ffffff;
  border-radius: 2px 2px 0 0;
  border-bottom: 1px solid rgba(0, 0, 0, .1);
}

.sf__city__text {
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  font-size: 16px;
  color: #777777;
}

.sf__city__link {
  margin-left: 4px;
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  font-size: 16px;
  text-decoration: underline;
  color: #00BFFF;
}

.sf__city__link:active {
  color: #FFDB4D;
}
/* ------------------ */

.cityFrom {
  display: flex;
  align-items: center;
}

.cityFrom .react-autosuggest__container {
  position: absolute;
  left: 0;
  top: 0;
}

.cityFrom .react-autosuggest__input {
  width: 200px;
  padding: 11px 16px;
  color: #454545;
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  font-size: 16px;
  border: 0;
  border-radius: 2px 2px 0 0;
}

.cityFrom .react-autosuggest__input--focused {
  outline: none;
}

.cityFrom .react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.cityFrom .react-autosuggest__suggestions-container {
  display: none;
}

.cityFrom .react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  top: 42px;
  width: 211px;
  background-color: #ffffff;
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  font-size: 16px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 2;
  box-shadow: 2px 2px 2px 0px rgba(0, 0, 0, 0.09);
}

.cityFrom .react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.cityFrom .react-autosuggest__suggestion {
  cursor: pointer;
  padding: 10px 20px;
}

.cityFrom .react-autosuggest__suggestion--highlighted {
  background-color: rgba(0,191,255, .9);
  color: #ffffff;
}

/* Media
======================= */

@media (max-width: 1070px) {
  .cityFrom .react-autosuggest__input {
    font-size: 14px;
  }

  .cityFrom .react-autosuggest__suggestions-container--open {
    top: 39px;
  }

  .sf__city__text {
    font-size: 14px;
  }
  
  .sf__city__link {
    font-size: 14px;
  }
}
