.advantages {
    margin-bottom: 50px;
}

.blue__title {
    padding-bottom: 16px;
    margin-bottom: 32px;
    text-align: center;
    font-size: 36px;
    line-height: 42px;
    font-weight: 700;
    color: #003399;
    position: relative;
}

.blue__title:after {
    background-color: #00BFFF;
    bottom: 0;
    content: '';
    display: block;
    height: 2px;
    left: 50%;
    position: absolute;
    transform: translate(-50%,0);
    width: 268px;
}

.advantages__item__wrapper {
    display: flex;
}

.advantages__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 33.33333%;
    padding-right: 20px;
}

.advantages__icon {
    width: 120px;
    height: 120px;
    margin-bottom: 24px;
}

.advantages__subtitle {
    margin-bottom: 16px;
    font-size: 24px;
    font-weight: 700;
    line-height: 28px;
    color: #383838;
}

.advantages__text {
    display: flex;
    align-items: start;
    padding: 0 0 20px 28px;
    font-size: 16px;
    line-height: 20px;
    font-weight: 400;
    color: #000000;
}

.adv-check-ico {
    margin-right: 10px;
}

@media (max-width: 900px) {
  .advantages__subtitle {
    font-size: 18px;
  }

  .advantages__text {
    font-size: 15px;
  }
}
