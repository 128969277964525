.destination__popover__wrapper {
  width: 412px;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 400;
  max-height: 285px;
  overflow: auto;
}


@media (max-width: 1073px) {
  .destination__popover__wrapper {
    font-size: 14px;
  }
}