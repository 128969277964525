.active__input {
  background-color: #eaf8ff !important;
}
.sf__input__item {
  /* width: 18%; */
  flex: 1 2 231px;
  min-width: 205px;
  position: relative;
}

.sf__input__item__wrapper {
  height: 62px;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 12px 16px;
  background: #ffffff;
  border-left: 1px solid rgba(0, 0, 0, .1);
}

.sf__input__item:first-child {
  border: 0;
  border-radius: 0 0 0 4px;
}

.sf__input__item img {
  margin: 0 16px 0 0;
}

.sf__input__item__label {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 12px;
  color: #777777;
  text-align: left;
}

.sf__input__item__text {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 17px;
  color: #454545;
  text-align: left;
}

.inputIco {
  display: block;
}

.destination_wrapper_input {
  flex: 2 1 285px;
}

.tourists_wrapper_input {
  flex: 2 1 265px;
}

/* Media
======================= */

@media (max-width: 1282px) {
  .inputIco {
    display: none;
  }
}

@media (max-width: 1073px) {
  .sf__input__item__text {
    font-size: 14px;
  }

  .sf_submit {
    font-size: 14px;
  }
  
  .sf__input__item {
    min-width: inherit;
  }

  .sf_submit {
    height: 48px;
  }

  .sf__input__item__wrapper {
    height: 48px;
  }

  .tourists_popover.popover {
    left: -80px;
  }
}

@media (max-width: 955px) {
  .input_value_diss_part {
    display: none;
  }

  .sf__input__item__label {
    display: none;
  }
}
