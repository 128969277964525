html, body {
  height: 100%;
}

body {
    margin: 0;
    background-color: #fff;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    color: #000000;
    -webkit-font-smoothing: antialiased;
    height: 100%;
}

button {
  background-color: transparent;
  cursor: pointer;
}

*,
*:before,
*:after {
    box-sizing: border-box;
}

h1, h2, h3, h4, h5, h6 {
    margin: 0;
}

ul, li, ol {
    padding: 0;
}

p {
    margin: 0 0 10px;
}

button {
  border: none;
  padding: 0;
  outline: none;
}

#root {
  height: 100%;
}

.wrapper {
  position: relative;
  min-height: 100%;
}

/* Content
=====================*/
.content {
  padding-bottom: 520px;
}

/* Container
=====================*/
.container {
    width: 100%;
    max-width: 1280px;
    min-width: 740px;
    margin: 0 auto;
    padding: 0 20px;
}

/* Title */
.blue__title {
    width: 665px;
    padding-bottom: 16px;
    margin-bottom: 32px;
    margin: 0 auto 32px auto;
    text-align: center;
    font-size: 36px;
    line-height: 42px;
    font-weight: 700;
    color: #003399;
    position: relative;
}

/* Button
=======================*/
.btn {
    display: inline-block;
    vertical-align: top;
    padding: 12px 20px;
    border-radius: 4px;
    border: 0;

    cursor: pointer;
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    font-size: 14px;
    text-decoration: none;

    transition: background .1s linear;

}

/* Yellow Button
=======================*/
.btn__yellow {
    color:#003399;
    background-color: #FFDB4D;
}

.btn__yellow:hover {
    background-color: #FFD017;
}

.btn__yellow:active {
    background-color: #FDCA00;
    box-shadow: 0px 0px 4px rgba(254, 86, 82, 0.25);
}

/* Yellow Frame Button
=======================*/
.btn_yellow_frame {
  color:#333333;
  border: 1px solid #FDCA00;
}

.btn_yellow_frame:hover {
  background-color: rgba(255, 208, 23, .2);
}

.btn_yellow_frame:active {
  background-color: #FDCA00;
  box-shadow: 0px 0px 4px rgba(254, 86, 82, 0.25);
}

/* Blue Button
=======================*/
.btn__blue {
  border-radius: 4px;
  background: #00BFFF;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
}

.btn__blue:hover {
  border-radius: 4px;
  background: #01B1EC;
  box-shadow: 0px 0px 4px 0px #41B3E5;
}

.btn__blue:active {
  border-radius: 4px;
  background: #009ED2;
  box-shadow: 0px 0px 4px 0px #41B3E5;
}

.app-logo-paused {
  color: red;
}

.c_pointer {
  cursor: pointer;
}

/* Fonts */
.text_14_400 {
  color: #333333;
  font-family: 'Roboto';
  font-size: 14px;
  font-style: 'normal';
  font-weight: 400;
}

.text_16_400 {
  color: #333333;
  font-family: 'Roboto';
  font-size: 16px;
  font-style: 'normal';
  font-weight: 400;
}

/* Checkbox */
/* Customize the label (the container) */
.checkbox_container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin: 10px 0;
  cursor: pointer;
  /* font-size: 16px; */
  color: #333333;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: flex;
  align-items: center;
}

/* Hide the browser's default checkbox */
.checkbox_container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}

/* On mouse-over, add a grey background color */
.checkbox_container:hover input ~ .checkmark {
  /* background-color: #ccc; */
}

/* When the checkbox is checked, add a blue background */
.checkbox_container input:checked ~ .checkmark {
  background-color: #00BFFF;
  border: 1px solid rgba(250, 250, 250, 0.2);
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkbox_container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkbox_container .checkmark:after {
  left: 7px;
  top: 3px;
  width: 5px;
  height: 10px;
  border: solid #ffffff;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.invisible {
  display: none;
}

/* text */
.text_h1 {
  font-family: 'Roboto';
  font-size: 34px;
  color: #333333;
  text-align: center;
  margin-bottom: 15px;
}

.text_h2 {
  font-family: 'Roboto';
  font-size: 22px;
  color: #333333;
  text-align: center;
  margin-bottom: 15px;
}

.text_p {
  font-family: 'Roboto';
  font-size: 16px;
  color: #333333;
  margin-bottom: 15px;
}

.content_block {
  margin-bottom: 20px;
  padding: 0 50px;
}