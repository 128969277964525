.faq_intro {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 235px;
    background: url("../../../images/about-back.jpg") no-repeat center;
    background-size: cover;
    min-width: 740px;
    margin-bottom: 35px;
}

.faq_intro__wave {
    position: absolute;
    width: 100%;
    height: 25px;
    bottom: -1px;
    left: 0;
    z-index: 100px;
    width: 100%;
    background-image: url('../../../images/white-wave.svg');
    background-position: center;
    background-repeat: repeat-x;
}

.faq_page_title {
    font-family: 'Roboto';
    font-size: 28px;
    color: #333333;
    font-weight: 600;
    margin-bottom: 20px;
}

.faq_title {
    font-family: 'Roboto';
    font-size: 20px;
    color: #333333;
    font-weight: 500;
}

.faq_answer {
    font-family: 'Roboto';
    font-size: 16px;
    color: #333333;
}

.faq_question_w {
    margin-bottom: 30px;
}