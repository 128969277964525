.ord_wrapper {
    margin-bottom: 30px;
    box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.10), 0px 1px 1px 0px rgba(0, 0, 0, 0.10);
    border-radius: 6px;
}

.ord_tour_desc_w {
    display: flex;
    position: relative;
}

.ord_hotel_img {
    width: 420px;
    height: 280px;
}

.ord_tour_desc {
    padding: 25px;
    width: 100%;
}

.ord_hotel_name {
    color: #333333;
    font-family: Roboto;
    font-size: 28px;
    font-weight: 700;
    margin-top: 15px;
}

.ord_location {
    font-family: 'Roboto';
    font-size: 12px;
    font-weight: 500;
    color: rgba(51, 51, 51, .5);
    margin: 8px 0;
}

.ord_location:before {
    content: "";
    background-image: url('../../../images/gray-pin.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 10px;
    height: 10px;
    float: left;
    margin: 3px 3px 0 0;
}

.ord_tour_desc_oprator {
    position: absolute;
    top: 0;
    right: 0;
    color: #333333;
    font-family: Roboto; 
    font-size: 22px;
    font-weight: 500;
}

.ord_details {
    padding: 0 15px 15px 0;
    color: #333333;
    font-family: Roboto; 
    font-size: 15px;
    font-weight: 400;
}

.ord_details_title {
    color: #333333;
    font-family: Roboto; 
    font-size: 14px;
    font-weight: 700;
}

.ord_details_desc {
   font-size: 14px;
   color: rgba(0, 0, 0, .6);
}

.ord_tour_desc_line1_w {
    position: relative;
}

.ord_status_w {
    width: 250px;
    height: 40px;
    color: #ffffff;
    font-size: 18px;
    font-weight: 500;
    font-family: Roboto; 
    position: absolute;
    top: 0;
    left: 0;
}

.ord_status {
    padding-top: 9px;
    width: 100%;
    height: 100%;
    border-radius: 0 0 6px 0;
    text-align: center;
}

.ord_status_negative {  
    background-color: #ad2218;
}

.ord_status_in_process {
    background-color: #f2cd13;
}

.ord_status_approved {
    background-color: #12a35f;
}

.ord_details_price_byn {
    color: rgba(51, 51, 51, .5);
    font-size: 14px;
}

.ord_details_price_orig {
    color: #333333;
    font-weight: 600;
    font-size: 16px;
}

.ord_tour_order_num {
    font-family: 'Roboto';
    font-size: 18px;
    font-weight: 500;
    color: #333333;
    margin-bottom: 15px;
}