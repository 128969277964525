.ap_form_rate_w {
    margin-bottom: 20px;
}

.ap_form_line_w {
    display: flex;
    align-items: center;
}

.ap_input {
    height: 40px;
    width: 60px;
    font-family: 'Roboto';
    font-size: 19px;
    font-weight: 500;
    color: #333333;
    text-align: center;
    margin: 0 20px;
}

.ap_form_curr {
    width: 32px;
    font-family: 'Roboto';
    font-size: 19px;
    font-weight: 500;
    color: #333333;
}

.ap_form_error {
    font-family: 'Roboto';
    font-size: 12px;
    color: rgb(199, 20, 23);
}

.ap_form_success {
    font-family: 'Roboto';
    font-size: 12px;
    color: green;
}