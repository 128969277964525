.input_wrapper {
    width: 220px;
    font-size: 12px;
    font-weight: 400;
    color: #b5b8ba;
    margin: 0 12px 12px 0;
}

.input_required {
    color: #DC143C;
}

.input.form_input.invalidInput:focus {
    outline-width: 0px;
    outline-style: solid;
    outline-color: rgb(199, 20, 23);
    box-shadow: 0px 0px 2px 1px rgba(199, 20, 23, 0.9);
}

.form_input {
    margin-top: 4px;
    width: 206px;
    padding: 8px;
    border: 1px solid #cdcfd1;
    border-radius: 3px;
    color: #454545;
}

input.form_input:focus {
    outline-width: 0px;
    outline-style: solid;
    outline-color: rgb(0, 51, 153);
    box-shadow: 0px 0px 2px 1px rgba(0, 51, 153, 0.9);
}

.input_form_error {
    margin-top: 3px;
    height: 14px;
    font-size: 12px;
    color: rgb(199, 20, 23);
}

.input_invalid_input {
    border-color: rgb(199, 20, 23);
    box-shadow: 0px 0px 2px 1px rgb(199, 20, 23, .3);
}

.rs_input {
    color: red;
    font-size: 10px;
}