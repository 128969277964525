.od_flight_wrapper {
    position: relative;
    margin-bottom: 30px;
    padding: 20px;
    box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.10), 0px 1px 1px 0px rgba(0, 0, 0, 0.10);
    border-radius: 6px;
}

.od_section_title {
    margin-bottom: 20px;
    font-family: Roboto;
    font-size: 25px;
    font-weight: 700;
    color: #333333
}

/* payment */
.od_input_wrapper {
    font-size: 12px;
    font-weight: 400;
    color: #b5b8ba;
    margin-right: 15px;
}

.input.od_form_input.invalidInput:focus {
    outline-width: 0px;
    outline-style: solid;
    outline-color: rgb(199, 20, 23);
    box-shadow: 0px 0px 2px 1px rgba(199, 20, 23, 0.9);
}

.od_form_input {
    margin-top: 4px;
    width: 252px;
    padding: 8px;
    border: 1px solid #cdcfd1;
    border-radius: 3px;
    color: #454545;
}

input.od_form_input:focus {
    outline-width: 0px;
    outline-style: solid;
    outline-color: rgb(0, 51, 153);
    box-shadow: 0px 0px 2px 1px rgba(0, 51, 153, 0.9);
}

.od_invalid_input {
    border-color: rgb(199, 20, 23);
}

.od_payment_wrapper {
    margin-bottom: 30px;
    padding: 20px;
    box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.10), 0px 1px 1px 0px rgba(0, 0, 0, 0.10);
    border-radius: 6px;
}

.od_contact_info {
    display: flex;
    align-items: center;
}

.od_tour_price_total {
    font-family: Roboto;
    font-size: 26px;
    font-weight: 500;
    color: #333333;
}

.od_tour_price_total_byn {
    margin-bottom: 15px;
    font-family: Roboto; 
    font-size: 16px;
    color: rgba(51, 51, 51, .5);
    font-weight: 500;
}

.od_login_form_error {
    margin-top: 10px;
    font-size: 12px;
    color: rgb(199, 20, 23);
}

.od_ckeckout_btn {
    margin-top: 14px;
    margin-left: 10px;
    height: 40px;
    padding: 0 20px;
}

.od_spinner_wrapper {
    padding-top: 150px;
    display: flex;
    justify-content: center;
    width: 100%;
    height: 500px;
}

.od_invisible {
    display: none;
}

.od_flight_not_found {
    font-family: Roboto;
    font-size: 16px;
    color: #333333;
}

.od_info_outdated {
    font-family: Roboto;
    font-size: 16px;
    color: #333333;
}

/* Modal */
.ch_invisible {
    display: none;
}

.od_warning {
    color: #FD4B44;
}