.v_intro {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 235px;
    background: url("../../../images/about-back.jpg") no-repeat center;
    background-size: cover;
    min-width: 740px;
    margin-bottom: 35px;
}

.v_intro__wave {
    position: absolute;
    width: 100%;
    height: 25px;
    bottom: -1px;
    left: 0;
    z-index: 100px;
    width: 100%;
    background-image: url('../../../images/white-wave.svg');
    background-position: center;
    background-repeat: repeat-x;
}

.v_empty {
    height: 300px;
}

.v_message {
    font-family: 'Roboto';
    font-size: 16px;
    color: #333333;
    margin-bottom: 20px;
}

.v_message_wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.v_form_wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.v_pass {
    margin-bottom: 6px;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 400;
    color: #b5b8ba;
}

.v_pass_hint {
    margin-top: 10px;
    font-size: 12px;
    color: #333333;
}