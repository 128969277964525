.ab_intro {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 235px;
    background: url("../../../images/about-back.jpg") no-repeat center;
    background-size: cover;
    min-width: 740px;
    margin-bottom: 35px;
}

.ab_intro__wave {
    position: absolute;
    width: 100%;
    height: 25px;
    bottom: -1px;
    left: 0;
    z-index: 100px;
    width: 100%;
    background-image: url('../../../images/white-wave.svg');
    background-position: center;
    background-repeat: repeat-x;
}

.ab_first_section_w {
    display: flex;
    justify-content: center;
    margin-bottom: 52px;
}

.ab_first_section_item {
    padding: 0 5px 0 5px;
    width: 420px;
    font-family: 'Roboto';
    font-size: 16px;
    color: #333333;
    text-align: justify;
    margin-right: 20px;
}

.ab_seconnd_section_w {
    margin-bottom: 35px;
    height: 290px;
    background: url("../../../images/about-second-sec-back.jpg") no-repeat center;
    background-size: cover;
}

.ab_advenages_w {
    padding-top: 20px;
    display: flex;
    justify-content: space-between;
}

.ab_advenages_item_w {
    display: flex;
}

.ab_advenages_item_title {
    font-family: 'Roboto';
    font-size: 21px;
    color: #ffffff;
    font-weight: 600;
    margin-bottom: 20px;
}

.ab_advenages_item_text_w {
    width: 250px;
}

.ab_advenages_item_text {
    font-family: 'Roboto';
    font-size: 16px;
    color: #ffffff;
}

.ab_euro {
    width: 80px;
    height: 80px;
    margin-right: 20px;
    margin-bottom: 10px;
}

.ab_shield {
    width: 80px;
    height: 80px;
    margin-right: 20px;
    margin-bottom: 10px;
}

.ab_human {
    width: 64px;
    height: 80px;
    margin-right: 20px;
    margin-bottom: 10px;
}

.ab_how_to_buy_w {
    margin-bottom: 35px;
    width: 100%;
    padding: 40px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.10), 0px 1px 1px 0px rgba(0, 0, 0, 0.10);
    border-radius: 6px;
}

.ab_how_to_buy_item {
    width: 360px;
    padding: 15px;
    margin-bottom: 20px;
}

.ab_how_title {
    margin-bottom: 25px;
    font-family: 'Roboto';
    font-size: 25px;
    font-weight: 600;
    color: #333333;
}

.ab_how_text {
    font-family: 'Roboto';
    font-size: 16px;
    color: #333333;
}

.buy_item_1 {
    background-image: url('../../../images/1.png');
    background-repeat: no-repeat;
}

.buy_item_2 {
    background-image: url('../../../images/2.png');
    background-repeat: no-repeat;
}

.buy_item_3 {
    background-image: url('../../../images/3.png');
    background-repeat: no-repeat;
}

.buy_item_4 {
    background-image: url('../../../images/4.png');
    background-repeat: no-repeat;
}

.buy_item_5 {
    background-image: url('../../../images/5.png');
    background-repeat: no-repeat;
}

.buy_item_6 {
    background-image: url('../../../images/6.png');
    background-repeat: no-repeat;
}

/* 840-1080 */
@media (max-width: 1080px) {
    .ab_advenages_item_w {
        flex-direction: column;
    }

    .ab_seconnd_section_w {
        height: 335px;
    }

    .ab_advenages_item_title {
        font-size: 19px;
        margin-bottom: 12px;
    }

    .ab_advenages_item_text {
        font-size: 14px;
    }
}

/* 740-840 */
@media (max-width: 840px) {
    .ab_advenages_item_title {
        font-size: 18px;
        margin-bottom: 12px;
    }

    .ab_advenages_item_text {
        font-size: 13px;
    }

    .ab_advenages_item_text_w {
        width: 200px;
    }

    .ab_euro {
        width: 70px;
        height: 70px;
    }

    .ab_shield {
        width: 70px;
        height: 70px;
    }

    .ab_human {
        width: 54px;
        height: 70px;
    }
}
