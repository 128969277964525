.reg_butt_w {
    display: flex;
    /* justify-content: center; */
    align-items: center;
}

.reg_btn {
    margin-top: 10px;
    padding: 0 0 0 75px;
    width: 250px;
    height: 40px;
}

.reg_submit_spinner_w {
    margin-left: 5px;
    width: 30px;
    padding-top: 3px;
}