.checkout_wrapper {
    margin-bottom: 30px;
    box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.10), 0px 1px 1px 0px rgba(0, 0, 0, 0.10);
    border-radius: 6px;
}

.ch_tour_desc_w {
    display: flex;
}

.ch_hotel_img {
    width: 420px;
    height: 280px;
}

.ch_tour_desc {
    padding: 25px;
    width: 100%;
}

.ch_hotel_name {
    color: #333333;
    font-family: Roboto;
    font-size: 28px;
    font-weight: 700;
    margin-top: 15px;
}

.ch_location {
    font-family: 'Roboto';
    font-size: 12px;
    font-weight: 500;
    color: rgba(51, 51, 51, .5);
    margin: 8px 0;
}

.ch_location:before {
    content: "";
    background-image: url('../../../images/gray-pin.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 10px;
    height: 10px;
    float: left;
    margin: 3px 3px 0 0;
}

.ch_details {
    padding: 0 15px 15px 0;
    color: #333333;
    font-family: Roboto; 
    font-size: 15px;
    font-weight: 400;
}

.ch_details_title {
    color: #333333;
    font-family: Roboto; 
    font-size: 14px;
    font-weight: 700;
}

.ch_details_desc {
   font-size: 14px;
   color: rgba(0, 0, 0, .6);
}

.ch_tour_desc_line1_w {
    position: relative;
}

.ch_tour_desc_oprator {
    position: absolute;
    top: 0;
    right: 0;
    color: #333333;
    font-family: Roboto; 
    font-size: 22px;
    font-weight: 500;
}

.ch_fligh_w {
    padding: 30px;
}

.ch_section_w {
    padding: 25px;
    margin-bottom: 30px;
    box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.10), 0px 1px 1px 0px rgba(0, 0, 0, 0.10);
    border-radius: 6px;
}

.ch_section_title {
    margin-bottom: 20px;
    font-family: Roboto;
    font-size: 21px;
    font-weight: 600;
    color: #333333
}

.ch_inputs_w {
    border-radius: 6px;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 25px;
}

.ch_tourst_data_w {
    margin: 40px 0px;
}

.ch_tourist_data_title {
    margin-bottom: 10px;
    color: #333333;
    font-family: Roboto; 
    font-size: 16px;
}

.ch_seria {
    width: 60px;
}

.ch_sex_slector_w {
    margin-top: 3px;
    display: flex;
    align-items: center;
}

.ch_sex_button_firsr {
    height: 34px;
    padding: 0 7px;
    color: #b5b8ba;
    font-family: Roboto; 
    font-size: 14px;
    font-weight: 500;
    border-radius: 3px 0 0 3px;
    border-top: 1px solid #cdcfd1;
    border-right: 0.5px solid #cdcfd1;
    border-bottom: 1px solid #cdcfd1;
    border-left: 1px solid #cdcfd1;
}

.ch_sex_button_sec {
    height: 34px;
    padding: 0 7px;
    color: #b5b8ba;
    font-family: Roboto; 
    font-size: 14px;
    font-weight: 500;
    border-radius: 0 3px 3px 0;
    border-top: 1px solid #cdcfd1;
    border-right: 1px solid #cdcfd1;
    border-bottom: 1px solid #cdcfd1;
    border-left: 0.5px solid #cdcfd1;
}

.ch_sex_btn_firt {
    /* border-right: 1px solid #cdcfd1; */
}

.ch_input_wrapper {
    font-size: 12px;
    font-weight: 400;
    color: #b5b8ba;
    margin: 0 12px 12px 0;
}

.ch_sex_selected {
    color: #ffffff;
    background-color: #00BFFF;
    border: 0;
}

/* payment **********/
.ch_payment_card_wrapper {
    display: flex;
    justify-content: center;
}

.ch_payment_card_w {
    position: relative;
    width: 552px;
    height: 313px;
}

.ch_card_front {
    width: 440px;
    height: 288px;
    border-radius: 12px;
    box-shadow: 0 4px 16px 0 rgba(0,0,0,.1);
    background-color: #f3fafd;
    position: relative;
    top: 0;
    left: 0;
    z-index: 2;
    padding: 24px;
}

.ch_cards {
    margin-bottom: 10px;
    width: 367px;
    display: flex;
    justify-content: end;
}

.ch_cards_ico {
    height: 21px;
}

.ch_card_back {
    width: 440px;
    height: 288px;
    border-radius: 12px;
    position: relative;
    z-index: 1;
    position: absolute;
    background-color: #dceaef;
    right: 0;
    bottom: 0;
}

.ch_card_black_line {
    margin-top: 33px;
    background: #000;
    height: 48px;
    margin-bottom: 18px;
}

.ch_card_term_w {
    display: flex;
    align-items: center;
    /* margin: 21px 0 10px 0; */
}

.ch_card_term_label {
    margin: 0 32px 12px 0;
    width: 65px;
    font-size: 12px;
    font-weight: 400;
    color: #b5b8ba;
}

.ch_card_number input {
    width: 367px;
}

.ch_payment_card_w input {
    height: 38px;
    /* margin: 0; */
}

.ch_card_term_w input {
    width: 64px;
    margin-right: 4px;
}

.ch_card_holder input {
    width: 367px;
}

.ch_card_cvv {
    padding: 1px 13px 0 0;
    display: flex;
    justify-content: end;
}

.ch_card_cvv input {
    width: 64px;
}

.ch_card_cvv .input_form_error {
    width: 50px;
}

.ch_card_cvv .input_wrapper {
    width: 65px;
}

.ch_card_number .input_wrapper {
    margin: 0 0 6px 0;
}

.ch_card_term_w .input_wrapper {
    margin: 0 0 12px 0;
    width: 100px;
}

.ch_card_holder .input_wrapper {
    width: 300px;
}

.ch_pay_wrapper {
    margin-top: 50px;
    display: flex;
    justify-content: center;
}

.ch_pay_w {
    text-align: center;
}

.ch_fill_gaps {
    margin-top: 3px;
    font-size: 14px;
    color: #b5b8ba;
}

.ch_stop_sell_message {
    margin-top: 3px;
    font-size: 14px;
    color: #DC143C;
}

.ch_input_required {
    color: #DC143C;
}

.ch_timer_w {
    color: #333333;
    font-family: Roboto; 
    font-size: 19px;
    font-weight: 500;
}

.time_count_down {
    color: #00BFFF;
}

.ch_modal_w {
    position: relative;
}

.ch_outdated_modal_w {
    padding-top: 200px;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 5;
}

.ch_outdated_modal {
    width: 400px;
    height: 200px;
    border-radius: 6px;
    background-color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.ch_modal_text {
    font-family: Roboto; 
    font-size: 19px;
    font-weight: 500;
}

.ch_modal_button {
    margin-top: 15px;
}

.ch_spinner_wrapper {
    padding-top: 150px;
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.6);
    position: absolute;
    z-index: 3;
    top: 0;
    left: 0;
}

.ch_invisible {
    display: none;
}

.ch_payment_title {
    margin-bottom: 15px;
    font-family: Roboto; 
    font-size: 16px;
    color: #333333;
}

.ch_payment_final_price {
    font-family: Roboto; 
    font-size: 40px;
    color: #333333;
    font-weight: 500;
}

.ch_payment_final_price_byn {
    font-family: Roboto; 
    font-size: 16px;
    color: rgba(51, 51, 51, .5);
    font-weight: 500;
}

.ch_submit {
    display: flex;
    align-items: center;
    font-size: 18px;
    height: 46px;
    padding: 0 15px !important;
}

.ch_submit_spinner_w {
    margin-top: 3px;
    margin-left: 6px;
    width: 30px;
}

.ch_link_oferta {
    color: rgba(0, 53, 153, .8);
}