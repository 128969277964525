.sf__nights_popover_wrapper {
  padding: 24px 40px;
}

.sf__nights__selector__wrapper {
  display: flex;
  margin-bottom: 16px;
}

.sf__nights__selector {
  display: flex;
  align-items: center;
}

.sf__nights__selector img {
  margin: 0;
}

.sf__nights__selector:first-child {
  margin-right: 45px;
}

.selected__days {
  margin: 0 16px;
  font-family: 'Roboto', sans-serif;
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  color: #333333;
}

.selected__days__text {
  margin-right: 16px;
}

.sf__nights__select__result {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #333333;
  text-align: center;
}

.bold_font {
  font-weight: 700;
}

.selected__days__text {
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: rgba(147, 156, 176, 0.8);
}

.select_button {
  width: 28px;
  height: 28px;
}
