.description {
    margin-bottom: 50px;
}

.description__item__wrapper {
    margin: 0 auto;
    display: flex;
}

.description__title {
    text-align: center;
}

.description__item {
    width: 50%;
    padding: 0 15px;
}
