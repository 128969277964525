/* Intro
========================*/
.intro {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 550px;
    background: linear-gradient(to right, rgba(0, 0, 0, .45), transparent), url("../../../../images/intro-back-opt.jpg") no-repeat center;
    background-size: cover;
    min-width: 740px;
}

.intro__title {
    font-family: 'Roboto', sans-serif;
    font-size: 48px;
    line-height: 44px;
    color: #fff;
    font-weight: 700;
}

.intro__subtitle {
    margin-top: 15px;
    font-size: 24px;
    color: #fff;
    font-weight: 500;
    line-height: 28px;
}

.intro__wave {
    position: absolute;
    width: 100%;
    height: 25px;
    bottom: -1px;
    left: 0;
    z-index: 100px;
    width: 100%;
    background-image: url('../../../../images/blue-wave-new.svg');
    background-position: center;
    background-repeat: repeat-x;
}

.intro_warranty_w {
    margin: 41px 0 35px 0;
    display: flex;
    align-items: center;
}

.intro_warranty {
    margin-left: 5px;
    color: #ffffff;
    font-family: Roboto;
    font-size: 25px;
    font-weight: 500;
}
