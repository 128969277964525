.resort_section {
  padding: 3px 35px;
}

.suggestion_section {
  padding-top: 5px;
  text-align: center;
  font-size: 12px;
  font-weight: 500;
  color: rgba(147, 156, 176, 0.8);
}

.suggestion_section_top_line {
  border-top: 1px solid rgba(147, 156, 176, 0.25);
}

.suggestion_country {
  margin-left: 4px;
  font-weight: normal;
  font-size: 14px;
  color: rgba(147, 156, 176, 0.8);
}

.resort_country {
  color: rgba(147, 156, 176, 0.8);
}
