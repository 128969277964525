.frt_raund_trip_w {
    padding: 10px 0 40px 40px;
}

.frt_fligh_depart_wrapper {
    padding-bottom: 20px;
    max-width: 850px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 2px dashed rgba(0, 0, 0, 0.1);
}

.frt_fligh_ico {
    width: 25px;
    height: 25px;
}

.frt_fligh_time {
    margin-bottom: 8px;
    font-family: Roboto;
    font-size: 32px;
    font-weight: 700;
    color: #333333
}

.frt_fligh_info {
    width: 105px;
    font-family: Roboto;
    font-size: 12px;
    font-weight: 400;
    color: #333333
}

.frt_fligh_return_wrapper {
    padding-top: 20px;
    max-width: 850px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.frt_flight_period {
    width: 90px;
    margin: 0 16px;
    text-align: center;
    position: relative;
    font-family: Roboto;
    font-size: 12px;
    font-weight: 400;
    color: rgba(51, 51, 51, .7);
    border-bottom: 1px dashed #00BFFF;
}

.frt_flight_perifrt_w {
    display: flex;
}

.frt_airport {
    font-family: Roboto;
    font-size: 14px;
    font-weight: 500;
    color: #00BFFF;
    padding: 3px;
    border-radius: 3px;
    border: 1.5px solid #00BFFF;
}

.frt_airport_dot {
    position: absolute;
    width: 12px;
    height: 12px;
    border-radius: 6px;
    border: 2px solid #00BFFF;
}

.dot_left {
    bottom: -6px;
    left: -12px;
}

.dot_right {
    bottom: -6px;
    right: -12px;
}

.frt_fligh_baggege_w {
    display: flex;
}

.frt_flight_baggage {
    padding-top: 10px;
    padding-left: 7px;
    width: 28px;
    height: 28px;
    font-family: Roboto;
    font-size: 12px;
    font-weight: 400;
    color: #333333;
    background-image: url('../../../images/baggage.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.frt_flight_laggage {
    padding-top: 11px;
    padding-left: 10px;
    width: 28px;
    height: 28px;
    font-family: Roboto;
    font-size: 12px;
    font-weight: 400;
    color: #333333;
    background-image: url('../../../images/laggage.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.frt_hard_laggage {
    padding-left: 7px;
}

.frt_flight_class {
    font-family: Roboto;
    font-size: 10px;
    font-weight: 400;
    color: rgba(51, 51, 51, 0.7);
}

.frt_flight_company_logo {
    width: 90px;
    margin-bottom: 3px;
}

.frt_flight_airline {
    font-size: 23px;
    font-weight: 500;
    margin-bottom: 4px;
}

.frt_flight_item_w {
    margin-bottom: 10px;
}

.frt_air_change {
    margin-top: 7px;
    font-family: Roboto;
    font-size: 12px;
    font-weight: 400;
}

.frt_select_flight {
    width: 158px;
    height: 42px;
    position: absolute;
    right: 15px;
    bottom: 15px;
}

.frt_order_scroll_btn {
    color: #333333;
    font-size: 16px;
}

.frt_invisible {
    display: none !important;
}

.frt_flicht_checked {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0;
    top: 0;
    width: 52px;
    height: 36px;
    background-color: #00BFFF;
    border-radius: 6px 0 6px 0;
}