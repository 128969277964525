.sf__tourists__popover__wrapper {
  padding: 24px 40px 16px 40px;
}
.form_collapse__item {
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.form_collapse__item:last-child {
  margin-bottom: 0;
}

.form__collapse__selector {
  display: flex;
  align-items: center;
  margin-left: 50px;
}

.form__collapse__selector img {
  margin: 0;
}

.form_collapse__text {
  display: flex;
  align-items: center;
}

.form__collapse__selector_quantity {
  margin: 0 16px;
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  color: #333333;
}

.form_collapse__text_ico {
  margin-right: 10px;
  width: 28px;
  height: 28px;
}

.adult_ico {
  width: 27px;
  height: 29px
}

.form__tourists__selector__item__left__text {
  display: flex;
  justify-content: space-between;
  width: 91px;
}

.form__tourists__selector__item__left__text__bold {
  margin-bottom: 5px;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #333333;
}

.form__tourists__selector__item__left__text__small {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #939CB0;
}

.form_tourists_select_button {
  width: 28ox;
  height: 28px;
}

.form_tourist_kids_section {
  margin-bottom: 15px;
  display: flex;
  justify-content: center;
}

.form_tourist_kids_section_title {
  margin: 0 10px;
  font-size: 12px;
  font-weight: 500;
  color: rgba(147, 156, 176, 0.8);
}

.form_tourist_kids_section_line {
  width: 55px;
  margin-bottom: 7px;
  border-bottom: 1px solid rgba(147, 156, 176, 0.5);
}

.form_tourist_button {
  padding: 8px 20px;
  margin-bottom: 8px;
  color: #FFF;
  font-family: Roboto;
  font-size: 13px;
  font-weight: 400;
}

.form_tourist_button_w {
  text-align: center;
}

.form_kinds_options_wrapper {
  position: absolute;
}

.form_kinds_options_w {
  background-color: #FFF;
  width: 153px;

  margin-top: 10px;
  border-radius: 8px;
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.2);
  position: relative;
  top: 0;
  left: 63px;
}

.form_kinds_options {
  overflow: auto;
  height: 150px;
}

.form_t_kids_item {
  font-family: 'Roboto';
  font-size: 14px;
  color: #333333;
  padding: 7px 13px;
  width: 100%;
  text-align: start;
}

.form_t_kids_item:hover {
  background-color: rgba(0,191,255, .9);
  color: #ffffff;
}

.form_kid_item {
  margin: 10px 0px;
}

.form_kids_opt_inv {
  display: none;
}

.form_kids_age_note {
  padding: 7px;
  font-family: 'Roboto';
  font-size: 12px;
  color: rgb(147, 156, 176);
  border-bottom: 1px solid rgba(147, 156, 176, .3);
}
