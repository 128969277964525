.DayPicker {
  width: 570px;
  padding: 10px;
}

.DayPicker-wrapper {
  outline: none;
}

.DayPicker-NavButton:active{
  border: 0;
}

.DayPicker-Day {
  outline: none;
}

.DayPicker-Day--selected {
  outline: none;
  width: 33.9px;
  height: 33.9px;
}

.DayPicker-NavButton {
  outline: none;
}

.rdp {
  --rdp-accent-color: #00BFFF;
  --rdp-background-color: rgba(0,191,255, 0.1);
}

@media (max-width: 1073px) {
  .rdp {
    --rdp-caption-font-size: 17px;
    --rdp-cell-size: 35px;
  }

  .rdp-button_reset {
    font-size: 13px;
  }
}

@media (max-width: 890px) {
  .rdp {
    --rdp-cell-size: 32px;
  }
}
 