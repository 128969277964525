.ap_rates_w {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}

.ap_rate_w {
    margin: 20px;
    padding: 20px;
    width: 280px;
    box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.1), 0px 1px 1px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
}

.ap_rate_operator {
    margin-bottom: 10px;
    font-family: 'Roboto';
    font-size: 22px;
    font-weight: 600;
    color: #333333;
}

.ap_orders_wrapper {
    margin: 20px;
    padding: 20px;
    box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.1), 0px 1px 1px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
}

.ap_order_w {
    padding: 20px;
    display: flex;
}

.ap_order_field {
    margin-right: 50px;
    font-family: 'Roboto';
    font-size: 16px;
    color: #333333;
}

.ap_text {
    font-family: 'Roboto';
    font-size: 16px;
    color: #333333;
}

.ap_order_table_line_head {
    display: flex;
    align-items: center;
    height: 30px;
}

.ap_order_table_line {
    display: flex;
    align-items: center;
    height: 30px;
    text-decoration: none;
    color: #333333;
}

.ap_order_table_line:hover {
    background-color: rgba(0, 0, 0, 0.1);
}

.ap_orders_table_column {
    width: 100px;
}

.ap_orders_table_head_item {
    font-family: 'Roboto';
    font-size: 16px;
    font-weight: 500;
    color: rgb(0, 191, 255);
}

.ap_orders_table_column_id {
    width: 250px;
}