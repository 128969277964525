@import "~react-image-gallery/styles/css/image-gallery.css";

/* image carusel *******/
.hotel_image_carusel_desctop {
    width: 420px;
    height: 381px;
}

.hotel_image_carusel_tablet {
    display: none;
}

.image-gallery-svg:hover {
    color: rgba(0,191,255, .8);
}

.image-gallery-thumbnail:hover {
    border: 4px solid #00BFFF;
}

.image-gallery-thumbnail.active {
    border: 4px solid #00BFFF;
}
/* *******/

.hotel_description_w {
    display: flex;
    flex-direction: row;
    box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.10), 0px 1px 1px 0px rgba(0, 0, 0, 0.10);
    border-radius: 6px;
    margin-bottom: 40px;
}

.hd_description_left_w {
    flex: 0 0;
}

.hd_description_right_w {
    flex: 1 1;
    display: flex;
    justify-content: space-between;
}

.hd_description_w {
    padding: 25px;
    flex: 1 1;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}

.hd_description_row1 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.hd_hotel_name {
    color: #333333;
    font-family: Roboto;
    font-size: 28px;
    font-weight: 700;
}

.hd_location {
    font-family: 'Roboto';
    font-size: 12px;
    font-weight: 500;
    color: rgba(51, 51, 51, .5);
    margin-top: 8px;
}

.hd_raiting_name {
    color: #003399;
    font-family: Roboto;
    font-size: 16px;
    font-weight: 700;
}

.hd_raiting_name_desc {
    color: #333333;
    font-family: Roboto;
    font-size: 12px;
    font-weight: 500;
    text-decoration-line: underline;
}

.hd_location:before {
    content: "";
    background-image: url('../../../images/gray-pin.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 10px;
    height: 10px;
    float: left;
    margin: 3px 3px 0 0;
}

.hd_raiting_desc {
    width: 75px;
    margin-right: 9px;
}

.hd_raiting_w {
    /* display: flex;
    flex-direction: row; */
}

.hd_raiting_like_w {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 67px;
    height: 32px;
    background-color: #8FCC14;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    padding: 6px 8px;
    margin-bottom: 6px;
}

.hd_raiting_like {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    color: #FFFFFF;
}

.hd_description_price_w {
    flex: 0 0 220px;
    flex-direction: column;
}

.hd_description_price {
    display: flex;
    font-family: Roboto;
    font-size: 24px;
    font-weight: 700;
    color: #333333;
    justify-content: center;
    align-items: center;
    height: 80px;
    width: 100%;
    background-color: #FFDB4D;
    text-decoration: none;
    border-radius: 0 0 6px 0;
    -webkit-border-radius: 0 6px 0 0;
    -moz-border-radius: 0 6px 0 0;
}

.hd_description_tourists_w {
    border-left: 1px solid rgba(0, 0, 0, 0.1);
}

.hd_description_tourists {
    padding: 40px 0;
    margin: 0 10px;
    color: #333333;
    text-align: center;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 700;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.hd_description_price_empty {
    padding: 80px;
    border-left: 1px solid rgba(0, 0, 0, 0.1);
}

.hd_description_facilities_w {
    display: flex;
    padding-top: 20px;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.hd_description_facility_w {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 5px;
}

.hd_description_facility {
    text-align: center;
}

.hd_short_description {
    margin-top: 16px;
    color: #333333;
    font-family: Roboto; 
    font-size: 14px;
    font-weight: 400;
}

.hd_desc_tour_price {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    color: #333333;
}

.hd_desc_tour_price_byn {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: rgb(69, 69, 69);
}

@media (max-width: 1000px) {
    .hotel_description_w {
        flex-direction: column-reverse;
    }

    .hotel_image_carusel_desctop {
        display: none;
    }

    .hotel_image_carusel_tablet {
        display: block;
    }

    .image-gallery-slide-wrapper.image-gallery-thumbnails-right {
        width: calc(100% - 230px);
    }

    .image-gallery-thumbnails-wrapper.image-gallery-thumbnails-right {
        width: 220px;
    }

    .hd_description_right_w {
        margin-bottom: 20px;
    }

    .image-gallery-thumbnail {
        width: 220px;
    }

    .thumbnails-wrapper.image-gallery-thumbnails-right .image-gallery-thumbnails {
        left: 2px;
    }
}

@media (max-width: 768px) {
    .image-gallery-thumbnail {
        width: 220px;
    }
}
