.directions {
    margin-bottom: 50px;
}

.directions__wrapper {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
}

.directions__item {
    width: 33.33333%;
    border-radius: 8px;
    box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.1), 0px 1px 1px rgba(0, 0, 0, 0.1);
}

.directions__item:nth-child(2) {
  margin: 0 30px;
}

.directions__oae__img {
    height: 220px;
    background: url("../../../../images/dubai.jpg") center no-repeat;
    background-size: cover;
    border-radius: 8px 8px 0 0;
}

.directions__turkey__img {
    width: 100%;
    height: 220px;
    border-radius: 8px 8px 0 0;
}

.directions__egypt__img {
    height: 220px;
    background: url("../../../../images/egypt.jpg") center no-repeat;
    background-size: cover;
    border-radius: 8px 8px 0 0;
}

.directions__description {
    padding: 24px;
}

.directions__description__firstline {
    display: flex;
    margin-bottom: 12px;
    justify-content: space-between;
    align-items: center;
}

.directions__description__name {
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    font-size: 28px;
    line-height: 33px;
    color: #000000;
}

.directions__description__city {
    width: 188px;
    margin-bottom: 12px;
    font-family: Roboto;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #000000;
    opacity: 0.6;
}

.directions__description__price {
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: #000000;
}

.directions__all {
    display: flex;
    align-items: center;
    justify-content: center;

    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    font-size: 22px;
    line-height: 26px;
    color: #00BFFF;
    text-decoration: none;
}

.directions__all:before {
    content: "";
    background: url('../../../../images/arrow-right.svg') center no-repeat /cover;
    width: 9px;
    height: 12px;
    margin: 0 4px 0 0;
}

.blue__label {
    padding: 12px 0;
    width: 114px;
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    color: #003399;
    border: 1px solid #003399;
}

/* Media
======================*/
@media (max-width: 950px) {
  .tailand__direction {
    display: none;
  }

  .directions__item {
      width: 50%;
  }
}
