.adm_order_but_w {
    display: flex;
}

.adm_order_but_approve {
    margin-right: 15px;
    background-color: #12a35f;
    color: #ffffff;
}

.adm_order_but_ip_progress {
    margin-right: 15px;
    background-color: #f2cd13;
    color: #ffffff;
}

.adm_order_but_decline {
    margin-right: 15px;
    background-color: #ad2218;
    color: #ffffff;
}

.adm_order_but_delete {
    background-color: #848d9c;
    color: #ffffff;
}

.adm_order_delete_w {
    margin-top: 15px;
}

.adm_order_message {
    margin-top: 15px;
    font-size: 14px;
    color: #333333;
}
