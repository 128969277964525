.operators {
    padding: 25px 0;
    margin-bottom: 60px;
    background-color: rgba(220, 244, 255, 0.3);
    min-width: 740px;
}

.operators__wrapper {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.operators__item {
    align-self: center;
    margin-right: 37px;
}

.op-tv-logo {
  height: 75px;
}

.op-tex-logo {
  height: 45px;
}

.aero-travel-logo {
  height: 55px;
  margin-bottom: 22px;
}

.voyage-tour-logo {
  height: 45px;
  margin-bottom: 12px;
}

.op-biblio-g-logo {
  height: 45px;
}

.op-kax-u-logo {
  height: 60px;
}

/* @media (max-width: 980px) {
  .op-tv-operator {
    display: none;
  }
} */
/* 
@media (max-width: 800px) {
  .aero-travel-operator {
    display: none;
  }
} */
